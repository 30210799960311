$('h4[id]').each(function() {
    var target = this.id;
    var title = $(this).text();
    title = title.substring(2);
  $('#anchor_nav').append(function() {
    return '<li><a id="anchor_' + target + '" href="#' + target + '"><h6>' + title + '</h6></a></li>';
  });
});

(function(document, history, location) {
  var HISTORY_SUPPORT = !!(history && history.pushState);

  var anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 70,

    init: function() {
      this.scrollToCurrent();
      $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
        $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
    },

    getFixedOffset: function() {
      return this.OFFSET_HEIGHT_PX;
    },

    scrollIfAnchor: function(href, pushToHistory) {
      var match, anchorOffset, anchor;

      if(!this.ANCHOR_REGEX.test(href)) {
        return false;
      }
      
        match = document.getElementById(href.slice(1));
        anchor = '#anchor_' + href.slice(1) +' h6';
      
      if(match) {
        anchorOffset = $(match).offset().top - this.getFixedOffset();
          $('html, body').animate({ scrollTop: anchorOffset });
            $('#anchor_nav h6').removeClass('txt-primary');
            $(anchor).addClass('txt-primary');
        // Add the state to history as-per normal anchor links
        if(HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        }
      }
      

      return !!match;
    },
    
    scrollToCurrent: function(e) { 
      if(this.scrollIfAnchor(window.location.hash) && e) {
      	e.preventDefault();
      }
    },

    delegateAnchors: function(e) {
      var elem = e.target;

      if(this.scrollIfAnchor(elem.getAttribute('href'), true)) {
        e.preventDefault();
      }
    }
  };

	$(document).ready($.proxy(anchorScrolls, 'init'));
})(window.document, window.history, window.location);

function setActiveOnScroll(event){
    // Get the offset of the window from the top of page
    var windowPos = $(window).scrollTop();
    
    $('#anchor_nav li a[href^="#"]').each(function() { 
        var anchorId = $(this);
        var target = $(anchorId.attr("href"));
        var anchor = '#' + anchorId.attr("id") +' h6';
        var offset = 500;
        if (target.position().top + offset < windowPos) {
            $('#anchor_nav h6').removeClass('txt-primary');
            $(anchor).addClass('txt-primary');
        }
    });
}

$(window).on('scroll', function() {
    setActiveOnScroll();
});

//THEME
import darktheme from "./custom/dark-theme.js";
import defaults from "./custom/defaults.js";
//import navbar from "./custom/navbar.js";
import footer from "./custom/footer.js";
import parallax from "./custom/parallax.js";
import anchorNav from "./custom/anchorNav.js";
import avatar from "./custom/avatar.js";
import table from "./custom/table.js";

//EXTRAS
// import megaMenu from "./custom/megaMenu.js";
import fwv from "./custom/full_width_video.js";
// import gsap from "./custom/gsap.js";
import annimate from "./custom/animate.js";
//import cards from './custom/cards.js';
//import progressBar from './custom/progressBar.js';

//VueJS
//import footer from './custom/vue-scripts.js';

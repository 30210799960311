// Video Hero Banner (VHB)
// Developer: Michael Spellacy (Spell), https://michaelspellacy.com

// Note: If you have a PWA, please see https://github.com/michaelspellacy/video-video-banner/issues/7 before using
var videoBanner = document.getElementById("video-banner");
if (videoBanner) {
  var videoBannerVideo = videoBanner.getAttribute("data-banner-video");
} else {
  var videoBannerVideo = null;
}

// Check if banner exists.

if (videoBannerVideo !== null) {
  // Check Cookie. If set to true, pause video.

  var videoBannerPaused = getCookie("videoBannerPaused");

  // Set "active" hook to main element

  videoBanner.classList.add("video-banner-active");

  // Variables

  var videoBannerAll = videoBanner.getAttribute("data-banner-all");
  var videoBannerCaption = videoBanner.getAttribute("data-banner-caption");
  var videoBannerDescription = videoBanner.getAttribute(
    "data-banner-description"
  );
  var videoBannerDesktop = videoBanner.getAttribute("data-banner-desktop");
  var videoBannerImage = document.getElementById("video-banner-image");
  var videoBannerMedia = videoBanner.getAttribute("data-banner-media");
  var videoBannerMobile = videoBanner.getAttribute("data-banner-mobile");
  var videoBannerPlay = "Play Background Animation";
  var videoBannerPause = "Pause Background Animation";
  var videoBannerState = "paused";

  // Create: Video

  var videoBannerVideo = document.createElement("video");
  videoBannerVideo.id = "video-banner-video";
  videoBannerVideo.setAttribute("aria-label", "Background Animation");
  videoBannerVideo.setAttribute("loop", "");
  videoBannerVideo.setAttribute("playsinline", "");
  videoBannerVideo.setAttribute("disableRemotePlayback", "");

  // If captions are present, then controls have to be present

  if (videoBannerCaption !== null) {
    videoBannerVideo.setAttribute("controls", "");
  }

  if (videoBannerCaption !== null || videoBannerDescription !== null) {
    videoBannerVideo.setAttribute("crossorigin", "anonymous");
  }

  // TODO: Add fallback Image

  // Add: Video

  videoBanner.appendChild(videoBannerVideo);

  // Add: Audio Description

  if (videoBannerDescription !== null) {
    var videoBannerDescValue = videoBannerDescription.split(",");
    var n = 3;

    for (var i = 0; i < videoBannerDescValue.length; i += n) {
      // Append track to video

      var videoBannerAudioDescription = document.createElement("track");
      videoBannerAudioDescription.setAttribute("kind", "descriptions");
      videoBannerAudioDescription.setAttribute(
        "srclang",
        videoBannerDescValue[i + 1].trim()
      );
      videoBannerAudioDescription.setAttribute(
        "label",
        videoBannerDescValue[i + 2].trim()
      );
      videoBannerAudioDescription.setAttribute(
        "src",
        videoBannerDescValue[i].trim()
      );

      videoBannerVideo.appendChild(videoBannerAudioDescription);
    }
  }

  // Add: Audio Description

  if (videoBannerCaption !== null) {
    var videoBannerCaptionValue = videoBannerCaption.split(",");
    var n = 3;

    for (var i = 0; i < videoBannerCaptionValue.length; i += n) {
      // Append track to video

      var videoBannerAudioCaption = document.createElement("track");
      videoBannerAudioCaption.setAttribute("kind", "captions");
      videoBannerAudioCaption.setAttribute(
        "srclang",
        videoBannerCaptionValue[i + 1].trim()
      );
      videoBannerAudioCaption.setAttribute(
        "label",
        videoBannerCaptionValue[i + 2].trim()
      );
      videoBannerAudioCaption.setAttribute(
        "src",
        videoBannerCaptionValue[i].trim()
      );

      videoBannerVideo.appendChild(videoBannerAudioCaption);
    }
  }

  // Create: Play/Pause Button

  var videoBannerButton = document.createElement("button");
  videoBannerButton.id = "video-banner-button";

  // Fire Viewport Width

  var mediaQuery = window.matchMedia(videoBannerMedia);
  mediaQuery.addListener(viewPortWidth);
  viewPortWidth(mediaQuery);

  // Fire Prefers Reduced Motion

  var motionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
  motionQuery.addListener(viewportMotion);
  viewportMotion(motionQuery);

  // Add: Play/Pause Button

  // Note: The pause button should _never_ be removed from the UI (unless captions present, which will add controls). This is an important accessibility feature.
  // While script does make use of prefers-reduced-motion, we can't fully depend on it.

  if (videoBannerCaption === null) {
    videoBanner.appendChild(videoBannerButton);
  }

  // Event: Play/Pause Button

  videoBannerButton.onclick = function () {
    if (videoBanner.classList.contains(videoBannerState)) {
      document.cookie =
        "videoBannerPaused=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      playVideo();
    } else {
      pauseVideo();

      document.cookie = "videoBannerPaused=true; path=/";
    }
  };
}

// Get Cookie

function getCookie(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value !== null ? unescape(value[1]) : null;
}

// Pause Video

function pauseVideo() {
  videoBannerVideo.pause();
  videoBanner.classList.add(videoBannerState);
  videoBannerButton.setAttribute("aria-label", videoBannerPlay);

  if (videoBannerImage !== null) {
    videoBannerVideo.setAttribute("preload", "none");
    videoBannerVideo.setAttribute("poster", videoBannerImage.src);
  }
}

// Play Video

function playVideo() {
  videoBannerVideo.play();
  videoBanner.classList.remove(videoBannerState);
  videoBannerButton.setAttribute("aria-label", videoBannerPause);
}

// Viewport Width

function viewPortWidth(mediaQuery) {
  if (videoBannerAll !== null) {
    videoBannerVideo.setAttribute("src", videoBannerAll);
  } else {
    if (mediaQuery.matches) {
      videoBannerVideo.setAttribute("src", videoBannerDesktop);
    } else {
      videoBannerVideo.setAttribute("src", videoBannerMobile);
    }
  }

  videoBannerVideo.load();

  // Only mute video if captions are not present (video will not autoplay under this condition)

  if (videoBannerCaption === null) {
    videoBannerVideo.muted = true;
  }

  // If cookie exists, then pause video

  if (videoBannerPaused !== null) {
    videoBanner.classList.add(videoBannerState);
  } else {
    videoBannerButton.setAttribute("aria-label", videoBannerPause);
  }

  if (videoBanner.classList.contains("paused")) {
    if (videoBannerImage !== null) {
      videoBannerVideo.setAttribute("preload", "none");
      videoBannerVideo.setAttribute("poster", videoBannerImage.src);
    }

    videoBannerVideo.pause();
    videoBannerButton.setAttribute("aria-label", videoBannerPlay);
  } else {
    videoBannerVideo.play();
  }

  // Since this is decorative, let us disable the video menu.

  if (videoBannerDescription === null || videoBannerCaption === null) {
    videoBannerVideo.oncontextmenu = function () {
      return false;
    };
  }
}

// Prefers Reduced Motion

function viewportMotion(motionQuery) {
  if (motionQuery.matches) {
    pauseVideo();
  }
}

if (document.getElementById("comparison-table")) {
  // Vérification du breakpoint de l'appareil
  var breakpointMax = 991;
  var currentScreenWidth = window.innerWidth;

  if (currentScreenWidth <= breakpointMax) {
    const dBtn = document.getElementById("dailyBtn");
    const wBtn = document.getElementById("weeklyBtn");
    const mBtn = document.getElementById("monthlyBtn");
    const yBtn = document.getElementById("yearlyBtn");

    // Sélectionne toutes les div avec les classes "daily", "weekly", "monthly" et "yearly"
    var divs = document.querySelectorAll(".daily, .weekly, .monthly, .yearly");

    // Crée un objet pour stocker les valeurs par classe
    var valeursParClasse = {
      daily: [],
      weekly: [],
      monthly: [],
      yearly: [],
    };

    // Parcourt chaque div et récupère la valeur innerText
    for (var i = 0; i < divs.length; i++) {
      var div = divs[i];
      var classe = div.className;
      var valeur = div.innerText;

      // Ajoute la valeur au tableau correspondant à la classe
      if (classe.includes("daily")) {
        valeursParClasse.daily.push(valeur);
      } else if (classe.includes("weekly")) {
        valeursParClasse.weekly.push(valeur);
      } else if (classe.includes("monthly")) {
        valeursParClasse.monthly.push(valeur);
      } else if (classe.includes("yearly")) {
        valeursParClasse.yearly.push(valeur);
      }
    }

    // Fonction de remplacement des valeurs pour la classe 'daily' lorsque le bouton 'mBtn' est cliqué
    dBtn.addEventListener("click", function () {
      var dailyDivs = document.getElementsByClassName("daily");

      for (var j = 0; j < dailyDivs.length; j++) {
        var div = dailyDivs[j];
        var valeur = valeursParClasse.daily[j];

        div.innerText = valeur;
      }

      dBtn.classList.add("active");
      wBtn.classList.remove("active");
      mBtn.classList.remove("active");
      yBtn.classList.remove("active");
    });

    wBtn.addEventListener("click", function () {
      var dailyDivs = document.getElementsByClassName("daily");

      for (var j = 0; j < dailyDivs.length; j++) {
        var div = dailyDivs[j];
        var valeur = valeursParClasse.weekly[j];

        div.innerText = valeur;
      }

      dBtn.classList.remove("active");
      wBtn.classList.add("active");
      mBtn.classList.remove("active");
      yBtn.classList.remove("active");
    });

    mBtn.addEventListener("click", function () {
      var dailyDivs = document.getElementsByClassName("daily");

      for (var j = 0; j < dailyDivs.length; j++) {
        var div = dailyDivs[j];
        var valeur = valeursParClasse.monthly[j];

        div.innerText = valeur;
      }

      dBtn.classList.remove("active");
      wBtn.classList.remove("active");
      mBtn.classList.add("active");
      yBtn.classList.remove("active");
    });

    yBtn.addEventListener("click", function () {
      var dailyDivs = document.getElementsByClassName("daily");

      for (var j = 0; j < dailyDivs.length; j++) {
        var div = dailyDivs[j];
        var valeur = valeursParClasse.yearly[j];

        div.innerText = valeur;
      }

      dBtn.classList.remove("active");
      wBtn.classList.remove("active");
      mBtn.classList.remove("active");
      yBtn.classList.add("active");
    });

    // Initialisation des boutons actifs
    dBtn.classList.add("active");
    wBtn.classList.remove("active");
    mBtn.classList.remove("active");
    yBtn.classList.remove("active");
  }
}

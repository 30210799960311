// const toggleEls = document.querySelector(".toggle-els");

// toggleEls.addEventListener("click", function (event) {
//   if (!event.target.classList.contains("toggle-btn")) return;

//   const location = event.target.dataset.location;
//   const itemDiv = document.getElementById(location);

//   if (!itemDiv) return;

//   // On enlève la classe "active" de tous les boutons et divs
//   const buttons = toggleEls.querySelectorAll(".toggle-btn");
//   buttons.forEach(function (button) {
//     button.classList.remove("active");
//   });

//   const divs = toggleEls.querySelectorAll(".item-div");
//   divs.forEach(function (div) {
//     div.classList.remove("active");
//   });

//   // On ajoute la classe "active" au bouton cliqué et à la div correspondante
//   event.target.classList.add("active");
//   itemDiv.classList.add("active");
// });

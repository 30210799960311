(function ($) {
  $(function () {
    sr = ScrollReveal();
    sr.debug = true;
    //Generals
    // sr.reveal('section', {
    //     duration: 3000,
    //     opacity: 0.2

    // });
    // sr.reveal('.container', {
    //     duration: 1500,
    //     opacity: 0

    // });
    // sr.reveal('h2', {
    //     duration: 2000,
    //     origin:'top',
    //     distance:'50px',
    //     viewFactor: 0.2

    // });
    // sr.reveal('h1', {
    //     duration: 2000,
    //     origin:'top',
    //     distance:'50px',
    //     viewFactor: 0.2

    // });
  });
})(jQuery);

document.addEventListener("DOMContentLoaded", function (event) {
  //wait until images, links, fonts, stylesheets, and js is loaded
  window.addEventListener("load", function (e) {
    // Remove preload class
    let hidden = document.querySelectorAll(".preload"),
      showContent = function () {
        hidden.forEach((elem) => {
          elem.classList.remove("preload");
        });
      };
    window.requestAnimationFrame(showContent);
    /**
     *
     * Heading text animation
     *
     */
    const swiftUpElements = document.querySelectorAll(".swift-up-text");

    swiftUpElements.forEach((elem) => {
      const words = elem.textContent.split("  ");
      elem.innerHTML = "";

      words.forEach((el, index) => {
        words[index] = `<span><i>${words[index]}</i></span>`;
      });

      elem.innerHTML = words.join(" ");

      const children = document.querySelectorAll("span > i");
      children.forEach((node, index) => {
        node.style.animationDelay = `${index * 0.2}s`;
      });
    });

    // Toggle blog categories on mobile (upto 992px)
    const toggleBtn = document.getElementById("toggle-btn");
    const toggleList = document.getElementById("toggle-list");
    if (document.getElementById("toggle-btn")) {
      toggleBtn.addEventListener("click", function () {
        this.classList.toggle("open");
        toggleList.classList.toggle("view");
      });
    }
  });

  // DEPREACTED SEE gsap.js for the solution
  // Start / pause video when not in viewport
  //   $("#video-banner wistia-video").each(function () {
  //     $(this).prop({
  //       controls: false,
  //       controlslist: "nodownload",
  //     });
  //     const observer = new window.IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {
  //           if (this.paused) {
  //             // $(this).prop("muted", true);
  //             this.play();
  //           }
  //         }
  //         // } else {
  //         //   this.pause();
  //         // }
  //       },
  //       {
  //         threshold: 0.9,
  //       }
  //     );
  //     observer.observe(this);
  //   });
});

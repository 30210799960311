// Select the button
const btn = document.getElementById("bm__theme-switcher");
// Check for dark mode preference at the OS level
const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
const prefersLightScheme = window.matchMedia("(prefers-color-scheme: light)");

// Get the user's theme preference from local storage, if it's available
let currentTheme = localStorage.getItem("theme");

if (!currentTheme) {
  if (prefersDarkScheme.matches) {
    localStorage.setItem("theme", "dark");
    localStorage.setItem("btnlog", "first visit with dark mode");
    document.body.classList.add("dark-theme");
    document.body.classList.remove("light-theme");
    btn.checked = true;
  }
  if (prefersLightScheme.matches) {
    localStorage.setItem("theme", "light");
    localStorage.setItem("btnlog", "first visit with light mode");
    document.body.classList.add("light-theme");
    document.body.classList.remove("dark-theme");
    btn.checked = false;
  }
}

// If the user's preference in localStorage is dark...
if (currentTheme == "dark") {
  // ...let's toggle the .dark-theme class on the body
  document.body.classList.add("dark-theme");
  document.body.classList.remove("light-theme");
  localStorage.setItem("btnlog", "Dark mode stored in local storage");
  btn.checked = true;
  // Otherwise, if the user's preference in localStorage is light...
} else if (currentTheme == "light") {
  // ...let's toggle the .light-theme class on the body
  document.body.classList.add("light-theme");
  document.body.classList.remove("dark-theme");
  localStorage.setItem("btnlog", "Light mode stored in local storage");
  btn.checked = false;
}

// Listen for a click on the button
btn.addEventListener("click", function () {
  document.body.classList.toggle("light-theme");
  document.body.classList.toggle("dark-theme");

  // Default DARK MODE Settings
  if (prefersDarkScheme.matches) {
    if (document.body.classList.contains("light-theme")) {
      var theme = "light";
      this.checked = false;
    } else {
      var theme = "dark";
      this.checked = true;
    }
  }

  // Default DARK MODE Settings
  if (prefersLightScheme.matches) {
    if (document.body.classList.contains("dark-theme")) {
      var theme = "dark";
      this.checked = true;
    } else {
      var theme = "light";
      this.checked = false;
    }
  }

  localStorage.setItem("theme", theme);
});
